<template>
  <div class="statistics">
    <el-row>
      <el-col :span="11">
        <div class="clearfix left">
          <div
            class="fl leftTitle textOverflow"
            :title="$t('BusinessRedEnvelope ')"
          >
            {{ $t("BusinessRedEnvelope ") }}
          </div>
          <div class="fr">
            <div class="clearfix">
              <div class="color tc fr verification">{{ $t("writtenOff") }}</div>
            </div>
            <div class="clearfix item">
              <div class="fl labelLeft mainColor">{{ $t("quantity2") }}：</div>
              <div class="fr tc size">{{ info.cancel_count || 0 }}</div>
            </div>
            <div class="clearfix item">
              <div class="fl money">
                {{ $t("amount") }}(<span v-if="info.currency == 2">$</span>
                <span v-else>￥</span>)：
              </div>
              <div class="fr tc size">{{ info.cancel_amount |moneyFormat }}</div>
            </div>
          </div>
        </div>
      </el-col>
      <el-col :span="4">
        <div class="tc already">
          <div class="color">{{ $t("received") }}</div>
          <div class="num">{{ info.receive_count || 0 }}</div>
          <div class="num">{{ info.receive_amount |moneyFormat }}</div>
        </div>
      </el-col>
      <el-col :span="6">
        <div class="tc balance">
          <div class="color">
            {{ $t("AccountBalance ") }}
            <span :title="$t('time_end_amount')"
              ><i class="el-icon-question cursor"></i
            ></span>
          </div>
          <div class="num">-</div>
          <div class="num">{{ info.balance | moneyFormat }}</div>
        </div>
      </el-col>
      <el-col :span="3">
        <div
          class="clearfix record cursor"
          @click="toUrl"
          v-if="inspectButton('mem:acr:acr')"
        >
          <div class="mainColor fr">
            {{ $t("accountRecords") }}
          </div>
        </div>
      </el-col>
    </el-row>
  </div>
</template>
<script>
export default {
  props: {
    info: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {};
  },
  methods: {
    toUrl() {
      this.$router.push({
        path: "/console/conference/accountRecords",
      });
    },
  },
};
</script>
<style lang="less" scoped>
.statistics {
  padding: 32px;
  height: 162px;
  background: #fff;
  box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.19);
  .left {
    padding-right: 10%;
    border-right: 1px solid #e8e8e8;
    .leftTitle {
      font-size: 20px;
      max-width: 220px;
    }
    .fr {
    }
    .verification {
      width: 100px;
      color: rgba(0, 0, 0, 0.45);
    }
    .item {
      line-height: 40px;
      div {
        text-align: right;
      }
    }
    .money {
      color: #dd9740;
    }
  }
  .already {
    font-size: 24px;
    border-right: 1px solid #e8e8e8;
  }
  .balance {
    font-size: 24px;
    width: 60%;
    .explain {
      display: none;
    }
    i:hover {
      .explain {
        display: block;
      }
    }
  }
  .record {
    span {
      border: 1px solid #0083f6;
      border-radius: 50%;
      font-size: 10px;
      padding: 0 2px;
    }
  }
  .num {
    line-height: 40px;
  }
  .size {
    font-size: 24px;
    width: 100px;
    margin-left: 30px;
  }
  .color {
    color: rgba(0, 0, 0, 0.45);
    font-size: 14px;
  }
}
</style>