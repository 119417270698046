<template>
  <div class="addOrEditBusiness">
    <el-form
      :model="form"
      :label-width="LOCALE == 'en' ? '150px' : '120px'"
      :rules="rules"
      ref="ruleForm"
      size="small"
    >
      <div v-if="LOCALE == 'en'">
        <el-form-item :label="$t('redName')" prop="topic_en">
          <el-input
            v-model="form.topic_en"
            :placeholder="$t('Red_Name_en')"
            :readonly="isDisabled"
          ></el-input
        ></el-form-item>
        <el-form-item prop="topic_zh">
          <el-input
            v-model="form.topic_zh"
            type="text"
            :placeholder="$t('Red_Name_zh')"
            maxLength="32"
            show-word-limit
            :readonly="isDisabled"
          ></el-input
        ></el-form-item>
      </div>
      <div v-else>
        <el-form-item :label="$t('redName')" prop="topic_zh">
          <el-input
            v-model="form.topic_zh"
            :placeholder="$t('Red_Name_zh')"
            maxLength="32"
            type="text"
            show-word-limit
            :readonly="isDisabled"
          ></el-input
        ></el-form-item>
        <el-form-item prop="topic_en">
          <el-input
            v-model="form.topic_en"
            :placeholder="$t('Red_Name_en')"
            :readonly="isDisabled"
          ></el-input
        ></el-form-item>
      </div>
      <div class="clearfix">
        <el-form-item
          :label="$t('Issuance_time')"
          class="fl"
          style="width: 60%"
          prop="start_time"
        >
          <el-date-picker
            :readonly="isDisabled"
            type="datetime"
            :placeholder="$t('Starttime')"
            :picker-options="pickerOptions"
            v-model="form.start_time"
            format="yyyy-MM-dd HH:mm"
            size="small"
            style="width: 100%"
          ></el-date-picker>
        </el-form-item>
        <div class="fl" style="line-height: 32px">-</div>
        <el-form-item
          class="fr"
          label-width="0px"
          style="width: 38%"
          prop="end_time"
        >
          <el-date-picker
            :readonly="isDisabled"
            type="datetime"
            :placeholder="$t('Starttime')"
            v-model="form.end_time"
            :picker-options="pickerOptions"
            format="yyyy-MM-dd HH:mm"
            size="small"
            style="width: 100%"
          ></el-date-picker>
        </el-form-item>
      </div>
      <el-form-item :label="$t('totalIssued')" prop="total_num">
        <el-input
          v-model="form.total_num"
          :readonly="isDisabled"
          oninput="value=(parseInt((value=value.replace(/\D/g,''))==''?'0':value,10))"
          :placeholder="$t('enterTheNumber')"
          style="width: 200px"
        ></el-input
      ></el-form-item>
      <el-form-item :label="$t('UseThreshold')" prop="has_threshold">
        <el-radio-group
          v-model="form.has_threshold"
          @change="thresholdChange"
          :disabled="isDisabled"
        >
          <div class="threshold">
            <el-radio label="0">{{ $t("noUuseThreshold") }}</el-radio>
          </div>
          <div>
            <el-radio label="1"
              >{{ $t("orderFull") }}
              <el-input
                v-model="form.threshold"
                :placeholder="$t('enterOrderAmount')"
                style="width: 120px"
                type="number"
                :readonly="thresholdDisabled"
              ></el-input>
              <span
                v-if="
                  LOCALE == 'en' &&
                  form.currency != 2 &&
                  LOCALE == 'en' &&
                  currency != 2
                "
              >
                RMB
              </span>
              <span
                v-if="
                  LOCALE == 'zh' &&
                  form.currency != 2 &&
                  LOCALE == 'zh' &&
                  currency != 2
                "
              >
                元</span
              >
            </el-radio>
          </div>
        </el-radio-group>
      </el-form-item>
      <el-form-item :label="$t('discountAmount')" prop="discount">
        {{ $t("reduction") }}
        <span></span>
        <el-input
          v-if="form.currency == 2 || currency == 2"
          v-model="form.discount"
          :readonly="isDisabled"
          :placeholder="$t('ChooseRedEnvelope')"
          style="width: 150px"
          readonly
        ></el-input>

        <span v-else>
          <el-select
            v-model="form.discount"
            style="width: 150px"
            :placeholder="$t('ChooseRedEnvelope')"
            :readonly="isDisabled"
          >
            <el-option label="50" value="50"></el-option>
            <el-option label="100" value="100"></el-option>
          </el-select>
          <span v-if="LOCALE == 'zh'"> 元</span>
          <span v-if="LOCALE == 'en'"> RMB </span>
        </span>
      </el-form-item>
      <div class="clearfix">
        <el-form-item
          :label="$t('usageTime')"
          class="fl"
          style="width: 60%"
          prop="using_start_time"
        >
          <el-date-picker
            type="date"
            :placeholder="$t('Starttime')"
            v-model="form.using_start_time"
            size="small"
            style="width: 100%"
            :readonly="isDisabled"
            :disabled="usingDisabled"
            @change="usingStartTime"
          ></el-date-picker>
        </el-form-item>
        <div class="fl" style="line-height: 32px">-</div>
        <el-form-item
          class="fr"
          label-width="0px"
          style="width: 38%"
          prop="using_end_time"
        >
          <el-date-picker
            type="date"
            :placeholder="$t('enterDate')"
            v-model="form.using_end_time"
            :readonly="isDisabled"
            :disabled="usingDisabled"
            size="small"
            :picker-options="pickerOptions1"
            style="width: 100%"
          ></el-date-picker>
        </el-form-item>
      </div>
      <!-- <div class="clearfix">
        <div class="usingLabel fl">{{ $t("usageTime") }}</div>
        <el-radio-group
          v-model="form.using_time_type"
          class="fl using"
          @change="usingChange"
          :disabled="isDisabled"
        >
          <div>
            <el-radio label="1" style="display: flex; align-items: center">
              <div class="clearfix" style="display: inline-block">
                <el-form-item
                  label-width="0px"
                  class="fl usingTime"
                  prop="using_start_time"
                >
                  <el-date-picker
                    type="date"
                    :placeholder="$t('Starttime')"
                    v-model="form.using_start_time"
                    size="small"
                    style="width: 100%"
                    :readonly="isDisabled"
                    :disabled="usingDisabled"
                    @change="usingStartTime"
                  ></el-date-picker>
                </el-form-item>
                <div class="fl" style="line-height: 32px">-</div>
                <el-form-item
                  class="fr usingTime"
                  label-width="0px"
                  prop="using_end_time"
                >
                  <el-date-picker
                    type="date"
                    :placeholder="$t('enterDate')"
                    v-model="form.using_end_time"
                    :readonly="isDisabled"
                    :disabled="usingDisabled"
                    size="small"
                    :picker-options="pickerOptions1"
                    style="width: 100%"
                  ></el-date-picker>
                </el-form-item>
              </div>
            </el-radio>
          </div>
          <div class="usingType">
            <el-form-item prop="today" label-width="0px">
              <el-radio label="2">
                <span v-if="LOCALE == 'en'">
                  Available for
                  <el-input
                    v-model="form.today"
                    size="small"
                    style="width: 120px"
                    :readonly="isDisabled"
                    :disabled="todayDisabled"
                    type="number"
                  ></el-input>
                  days from the day of collection
                </span>
                <span v-else>
                  领取当日起
                  <el-input
                    v-model="form.today"
                    size="small"
                    style="width: 120px"
                    :readonly="isDisabled"
                    :disabled="todayDisabled"
                    type="number"
                  ></el-input>
                  天内可用
                </span>
              </el-radio>
            </el-form-item>
          </div>
          <div class="usingType">
            <el-form-item prop="nextDay" label-width="0px">
              <el-radio label="3">
                <span v-if="LOCALE == 'en'">
                  Available for
                  <el-input
                    v-model="form.nextDay"
                    size="small"
                    style="width: 120px"
                    :readonly="isDisabled"
                    :disabled="yesDisabled"
                    type="number"
                  ></el-input>
                  days from the day after receiving
                </span>
                <span v-else>
                  领取次日起
                  <el-input
                    v-model="form.nextDay"
                    size="small"
                    style="width: 120px"
                    type="number"
                    :readonly="isDisabled"
                    :disabled="yesDisabled"
                  ></el-input>
                  天内可用
                </span>
              </el-radio>
            </el-form-item>
          </div>
        </el-radio-group>
      </div> -->
      <el-form-item :label="$t('instructions')">
        <el-input
          type="textarea"
          maxlength="200"
          :rows="2"
          :placeholder="$t('ofTheBusiness')"
          v-model="form.instruction_zh"
          :readonly="isDisabled"
        >
        </el-input>
      </el-form-item>
      <el-form-item>
        <el-input
          type="textarea"
          :readonly="isDisabled"
          :rows="2"
          :placeholder="$t('ofTheBusinessEn')"
          v-model="form.instruction_en"
        >
        </el-input>
      </el-form-item>
      <el-form-item :label="$t('Limited')"
        ><span class="mainColor">{{ $t("lingqu_times") }}</span></el-form-item
      >
    </el-form>
    <div
      class="clearfix drawerFooter"
      v-if="!isDisabled && inspectButton('mem:yrm:mdi')"
    >
      <el-button
        type="primary"
        size="small"
        class="fr"
        style="margin-left: 16px; width: 80px"
        :loading="btnloading"
        @click="submitForm()"
        >{{ $t("save") }}</el-button
      >
      <el-button
        size="small"
        class="fr"
        @click="cencelForm"
        style="width: 80px"
        >{{ $t("cancel") }}</el-button
      >
    </div>
  </div>
</template>
<script>
export default {
  props: {
    editData: {
      type: Object,
      default: () => {
        return {};
      },
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
    currency: {
      type: Number || String,
      default: null,
    },
  },
  data() {
    return {
      form: {
        topic_en: "",
        topic_zh: "",
        start_time: "",
        end_time: "",
        total_num: "",
        discount: "",
        using_time_type: "1",
        nextDay: "",
        today: "",
        using_start_time: "",
        using_end_time: "",
      },
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() < Date.now() - 8.64e7;
        },
      },
      pickerOptions1: {},
      usingDisabled: false,
      todayDisabled: true,
      yesDisabled: true,
      btnloading: false,
      thresholdDisabled: true,
      rules: {
        topic_en: {
          validator: async (rule, value, callback) => {
            if (value == "" && !this.form.topic_zh) {
              callback(new Error(" "));
            }
          },
          trigger: "blur",
          required: true,
        },
        topic_zh: {
          validator: async (rule, value, callback) => {
            if (value == "" && !this.form.topic_en) {
              callback(new Error(" "));
            }
          },
          trigger: "blur",
          required: true,
        },
        start_time: [{ required: true, message: " ", trigger: "blur" }],
        end_time: [{ required: true, message: " ", trigger: "blur" }],
        total_num: {
          validator: async (rule, value, callback) => {
            if (value == "" || value == 0) {
              callback(new Error(" "));
            } else if (value > 1000) {
              callback(this.$t("maximumValue"));
            }
          },
          trigger: "blur",
          required: true,
        },
        has_threshold: [{ required: true, message: " ", trigger: "blur" }],
        discount: [{ required: true, message: " ", trigger: "blur" }],
        using_start_time: {
          validator: async (rule, value, callback) => {
            if (value == "" && this.form.using_time_type == 1) {
              callback(new Error(" "));
            }
          },
          trigger: "blur",
          required: true,
        },
        using_end_time: {
          validator: async (rule, value, callback) => {
            if (value == "" && this.form.using_time_type == 1) {
              callback(new Error(" "));
            }
          },
          trigger: "blur",
          required: true,
        },
        today: {
          validator: async (rule, value, callback) => {
            if (value == "" && this.form.using_time_type == 2) {
              callback(new Error(" "));
            } else if (this.form.using_time_type == 2 && value > 60) {
              callback(new Error(this.$t("60AtMost")));
            }
          },
          trigger: "blur",
          required: true,
        },
        nextDay: {
          validator: async (rule, value, callback) => {
            if (value == "" && this.form.using_time_type == 3) {
              callback(new Error(" "));
            } else if (this.form.using_time_type == 3 && value > 60) {
              callback(new Error(this.$t("60AtMost")));
            }
          },
          trigger: "blur",
          required: true,
        },
      },
    };
  },
  watch: {
    editData() {
      this.$refs.ruleForm.resetFields();
      let obj = Object.assign({}, this.editData);
      if (obj.has_threshold) {
        this.thresholdDisabled = false;
      }
      obj.has_threshold = obj.has_threshold.toString();
      obj.threshold = obj.threshold || "";
      obj.using_time_type = obj.using_time_type.toString();
      obj.using_start_time = obj.using_start_time * 1000;
      obj.using_end_time = obj.using_end_time * 1000;
      obj.start_time = obj.start_time * 1000;
      obj.end_time = obj.end_time * 1000;
      if (obj.using_time_type == 2) {
        obj.today = obj.effective_days;
      } else if (obj.using_time_type == 3) {
        obj.nextDay = obj.effective_days;
      }
      this.form = obj;
      if (this.form.currency == 2) {
        this.form.discount = "20";
      }
      // this.usingChange(this.form.using_time_type);
    },
  },
  mounted() {
    if (this.currency == 2) {
      this.form.discount = "20";
    }
    let start_time = this.MEETING_INFO.start_time;
    let end_time = this.MEETING_INFO.end_time;
    this.pickerOptions = {
      disabledDate(time) {
        return (
          moment(time).unix() < start_time || moment(time).unix() > end_time
        );
      },
    };
  },
  methods: {
    submitForm() {
      this.$refs.ruleForm.validate(async (valid) => {
        if (valid) {
          let formObj = Object.assign({}, this.form);
          let obj = {
            topic_en: this.form.topic_en,
            topic_zh: this.form.topic_zh,
          };
          if (formObj.start_time) {
            obj.start_time = moment(formObj.start_time).unix();
          }
          if (formObj.end_time) {
            obj.end_time = moment(formObj.end_time).unix();
          }
          if (formObj.has_threshold) {
            obj.threshold = formObj.threshold * 100 || 0;
          }
          if (formObj.using_time_type == 1) {
            obj.using_start_time = moment(formObj.using_start_time).unix();
            let endTime = moment(formObj.using_end_time).endOf("day");
            obj.using_end_time = moment(endTime).unix();
          } else {
            if (formObj.using_time_type == 2) {
              obj.effective_days = formObj.today;
            } else if (formObj.using_time_type == 3) {
              obj.effective_days = formObj.nextDay;
            }
          }
          if (formObj.id) {
            obj.red_envelope_business_id = formObj.id;
          }
          obj.has_threshold = formObj.has_threshold;
          obj.using_time_type = formObj.using_time_type;

          obj.discount = formObj.discount * 100;
          obj.instruction_zh = formObj.instruction_zh;
          obj.instruction_en = formObj.instruction_en;
          obj.total_num = formObj.total_num;
          if (formObj.id) {
            obj.red_envelope_business_id = formObj.id;
          }
          obj.topic_en = this.form.topic_en;
          obj.topic_zh = this.form.topic_zh;
          if (obj.start_time > obj.end_time) {
            this.$message.warning(this.$t("companyCatalog1v1Error9"));
            return;
          }
          this.btnloading = true;
          this.checkTimer(obj);
        }
      });
    },
    async checkTimer(value) {
      let params = {
        related_id: this.USER_INFO.company_id,
        meeting_id: this.MEETING_ID,
        time_low: value.start_time,
        time_high: value.end_time,
        red_envelope_business_id: this.form.id,
      };
      let result = await this.$store.dispatch(
        "baseConsole/check_red_envelope_time",
        params
      );
      if (result.success) {
        this.saveData(value);
      } else {
        this.$message.warning(this.$t("timerTitle"));
        this.btnloading = false;
      }
    },
    async saveData(params) {
      let _params = Object.assign(params, {
        meeting_id: this.MEETING_ID,
        related_id: this.USER_INFO.company_id,
        _NOCLEAR: true,
      });
      let result = await this.$store.dispatch(
        "baseConsole/addorEditRedEnvelope",
        _params
      );
      if (result.success) {
        this.$ssTip();
        this.$emit("isSaveChange", true);
      } else {
        if (result.errorcode == 1028) {
          this.$message.warning(this.$t("balanceTitle"));
        }
      }
      this.btnloading = false;
    },
    cencelForm() {
      this.$refs.ruleForm.resetFields();
      this.form = {
        topic_en: "",
        topic_zh: "",
        start_time: "",
        end_time: "",
        total_num: "",
        discount: "",
        using_time_type: "1",
        nextDay: "",
        today: "",
        using_start_time: "",
        using_end_time: "",
      };
      this.$emit("isSaveChange");
    },
    thresholdChange(e) {
      if (e == 1) {
        this.thresholdDisabled = false;
      } else {
        this.thresholdDisabled = true;
        this.form.threshold = " ";
      }
    },
    usingChange(e) {
      console.log(e);
      if (e == 1) {
        this.usingDisabled = false;
        this.todayDisabled = true;
        this.yesDisabled = true;
        this.form.today = "";
        this.form.nextDay = "";
      } else if (e == 2) {
        this.usingDisabled = true;
        this.todayDisabled = false;
        this.yesDisabled = true;
        this.form.using_start_time = "";
        this.form.using_end_time = "";
        this.form.nextDay = "";
      } else {
        this.usingDisabled = true;
        this.todayDisabled = true;
        this.yesDisabled = false;
        this.form.using_start_time = "";
        this.form.using_end_time = "";
        this.form.today = "";
      }
    },
    clearData() {
      this.form = {
        topic_en: "",
        topic_zh: "",
        start_time: "",
        end_time: "",
        total_num: "",
        discount: this.form.discount == 20 ? 20 : "",
        using_time_type: "1",
        nextDay: "",
        today: "",
        using_start_time: "",
        using_end_time: "",
      };
      this.usingDisabled = false;
      this.todayDisabled = true;
      this.yesDisabled = true;
      this.$refs.ruleForm.resetFields();
    },
    usingStartTime(e) {
      let timer = moment(e).unix();
      let timer1 = moment(e).unix() + 5270400;
      this.pickerOptions1 = {
        disabledDate(time) {
          return moment(time).unix() < timer || moment(time).unix() > timer1;
        },
      };
      this.form.using_end_time = "";
    },
  },
};
</script>
<style lang="less" scoped>
.addOrEditBusiness {
  padding-bottom: 90px;
  .drawerFooter {
    position: absolute;
    bottom: 0;
    right: 0;
    padding: 30px;
    width: 100%;
    background: #fff;
  }
}
.using {
  width: 420px;
  margin-bottom: 10px;
}
.usingLabel {
  width: 120px;
  text-align: right;
  padding-right: 12px;
  line-height: 38px;
  color: #606266;
  &::before {
    content: "*";
    color: #f56c6c;
    margin-right: 4px;
  }
}
.usingType {
  margin: 12px 0 12px;
}
.threshold {
  margin: 12px 0 12px;
}
.usingTime {
  width: 49%;
  margin-bottom: 0px !important;
}

/deep/.el-form-item {
  margin-bottom: 24px;
}
/deep/.el-radio__input.is-checked + .el-radio__label {
  color: #606266;
}
</style>