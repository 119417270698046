<template>
  <div class="redEnvelopeAdmin">
    <div class="envelopTotal">
      <statistics :info="envelopInfo"></statistics>
    </div>
    <div class="envelopList">
      <pageTitle :title="$t('BusinessRedEnvelope ')" :isBorder="true">
        <template slot="right">
          <div class="titleSlot">
            <el-button
              size="small"
              type="primary"
              @click="addChange"
              v-if="inspectButton('mem:yrm:syr')"
              >{{ $t("IssueBusinessRed") }}</el-button
            >
            <el-button
              size="small"
              type="primary"
              @click="showWith"
              v-if="inspectButton('mem:yrm:wro')"
              >{{ $t("Writeoff") }}</el-button
            >
          </div>
        </template>
      </pageTitle>
      <div class="search">
        <searchBox @commponentSearch="searchChange"></searchBox>
      </div>
      <div class="tableBlock">
        <el-table
          :data="tableData"
          style="width: 100%"
          :height="tableHeight"
          v-tableHeight="{ bottomOffset: 74 }"
          :tableLoading="loading"
        >
          <!-- <el-table-column prop="id" label="ID" width="100"> </el-table-column> -->
          <el-table-column
            prop="name"
            :label="$t('RedEnvelopeName')"
            width="250"
          >
            <template slot-scope="scope">
              <span
                class="mainColor cursor"
                @click="editChange(scope.row, true)"
              >
                {{
                  scope.row.topic_en | priorFormat(scope.row.topic_zh, LOCALE)
                }}</span
              >
            </template>
          </el-table-column>
          <el-table-column :label="$t('Issuance_time')" width="350">
            <template slot-scope="scope">
              {{ scope.row.start_time | secondFormat("LLL") }}-{{
                scope.row.end_time | secondFormat("LLL")
              }}
            </template>
          </el-table-column>
          <el-table-column :label="$t('IssuanceStatus')" width="180">
            <template slot-scope="scope">
              <div class="color">
                <div v-show="scope.row.isTimer == 1 && !scope.row.is_abort">
                  {{ $t("haveNotStarte") }}
                </div>
                <div v-show="scope.row.is_abort">{{ $t("Terminated") }}</div>
                <div
                  class="mainColor"
                  v-show="scope.row.isTimer == 2 && !scope.row.is_abort"
                >
                  {{ $t("underway") }}
                </div>
                <div v-show="scope.row.isTimer == 3 && !scope.row.is_abort">
                  {{ $t("havemeetingEnd") }}
                </div>
              </div>
            </template>
          </el-table-column>
          <el-table-column :label="$t('amount')" width="100">
            <template slot-scope="scope">
              <span>{{ scope.row.receive_amount/100 }}</span> /
              <span>{{ scope.row.discount * scope.row.total_num }}</span>
            </template>
          </el-table-column>
          <el-table-column :label="$t('quantity2')" width="150">
            <template slot-scope="scope">
              <span class="mainColor cursor" @click="toReceive(scope.row, 1)">{{
                scope.row.receive_count
              }}</span>
              / <span>{{ scope.row.total_num }}</span></template
            >
          </el-table-column>
          <el-table-column :label="$t('used')">
            <template slot-scope="scope">
              <span class="mainColor cursor" @click="toReceive(scope.row, 2)">{{
                scope.row.cancel_count
              }}</span>
            </template>
          </el-table-column>
          <el-table-column :label="$t('handleTime')" width="180">
            <template slot-scope="scope">
              {{ scope.row.updated_time | secondFormat("LLLL") }}
            </template>
          </el-table-column>
          <el-table-column :label="$t('Edit')" width="100" fixed="right">
            <template slot-scope="scope">
              <alTableBtns
                :btnsList="scope.row.btnsList"
                :btnsIndex="scope.row.$index"
                :rowData="scope.row"
              ></alTableBtns>
              <!-- <el-button
                v-if="inspectButton('mem:yrm:sto')"
                type="text"
                v-show="!scope.row.is_abort && scope.row.isTimer == 2"
                @click="endData(scope.row)"
                >{{ $t("termination2") }}</el-button
              >
              <span
                v-if="inspectButton('mem:yrm:sto')"
                class="mainColor"
                v-show="!scope.row.is_abort && scope.row.isTimer == 2"
              >
                |
              </span>
              <el-button
                type="text"
                v-show="scope.row.start_time < scope.row.now_time"
                @click="toReceive(scope.row, 1)"
                >{{ $t("Get_details") }}</el-button
              >
              <el-button
                v-if="inspectButton('mem:yrm:mdi')"
                type="text"
                v-show="scope.row.start_time > scope.row.now_time"
                @click="editChange(scope.row)"
                >{{ $t("edit") }}</el-button
              >
              <span
                v-if="inspectButton('mem:yrm:mdi')"
                class="mainColor"
                v-show="scope.row.start_time > scope.row.now_time"
              >
                |
              </span>
              <el-button
                v-if="inspectButton('mem:yrm:del')"
                type="text"
                v-show="scope.row.start_time > scope.row.now_time"
                @click="delData(scope.row)"
                >{{ $t("delete") }}</el-button
              > -->
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="paginationBlock">
        <el-pagination
          layout="total, prev, pager, next"
          :total="total"
          :page-size="limit"
          :current-page.sync="start"
          @current-change="handlePageChange"
        >
        </el-pagination>
      </div>
    </div>
    <el-drawer
      :title="drawerTitle"
      size="700px"
      :visible.sync="drawerShow"
      direction="rtl"
    >
      <div style="padding: 0 24px">
        <addOrEdit
          @isSaveChange="isSaveChange"
          ref="addOrEdit"
          :currency="envelopInfo.currency"
          :editData="editDatas"
          :isDisabled="isDisabled"
        ></addOrEdit>
      </div>
    </el-drawer>
    <writeOffDialog ref="writeOff" @refresh="refresh"></writeOffDialog>
  </div>
</template>
<script>
import statistics from "~bac/components/conference/redEnvelopeAdmin/business/statistics";
import addOrEdit from "~bac/components/conference/redEnvelopeAdmin/business/addOrEditBusiness";
import searchBox from "~bac/components/conference/redEnvelopeAdmin/searchBox";
import pageTitle from "~bac/components/common/pageTitle";
import writeOffDialog from "~bac/components/conference/writeOffDialog/index";
import alTableBtns from "~/baseComponents/alTableBtns";
export default {
  components: {
    statistics,
    pageTitle,
    searchBox,
    addOrEdit,
    writeOffDialog,
    alTableBtns,
  },
  data() {
    return {
      loading: false,
      drawerTitle: this.$t("addBusinessRedEnvelope"),
      tableHeight: 100,
      total: 0,
      limit: 10,
      start: 1,
      drawerShow: false,
      tableData: [],
      envelopInfo: {},
      editDatas: {},
      delLoading: false,
      isDisabled: false,
    };
  },
  mounted() {
    this.getList();
    this.getEnvelopeInfo();
  },
  methods: {
    async getList(value) {
      try {
        let params = {
          meeting_id: this.MEETING_ID,
          start: this.start - 1,
          limit: this.limit,
          related_id: this.USER_INFO.company_id,
          account_type: 3, //1-用户账户，2-公司账户(现金)，3-公司虚拟账户（业务）
        };
        let _params = Object.assign(params, value);
        this.loading = true;
        let data = await this.$store.dispatch(
          "baseConsole/envelopeList",
          _params
        );
        if (data.success) {
          let now_time = moment().unix();
          data.data.forEach((it) => {
            it.now_time = now_time;
            it.discount = it.discount / 100;
            it.threshold = it.threshold / 100;
            if (it.start_time > now_time) {
              it.isTimer = 1;
            } else if (it.start_time <= now_time && now_time < it.end_time) {
              it.isTimer = 2;
            } else if (it.end_time < now_time) {
              it.isTimer = 3;
            } else {
              it.isTimer = "";
            }
            it.btnsList = [
              {
                text: this.$t("edit"),
                event: "editChange",
                otherParams: 1,
                notDisabled: it.start_time > it.now_time,
                auth: "mem:yrm:mdi",
              },
              {
                text: this.$t("termination2"),
                event: "endData",
                notDisabled: !it.is_abort && it.isTimer == 2,
                auth: "mem:yrm:sto",
              },
              {
                text: this.$t("Get_details"),
                event: "toReceive",
                notDisabled: it.start_time < it.now_time,
                auth: "mem:yrm:lqd",
              },
              {
                text: this.$t("delete"),
                event: "delData",
                notDisabled: it.start_time > it.now_time,
                auth: "mem:yrm:del",
              },
            ];
          });
          this.tableData = data.data;
          this.total = data.total;
          this.$forceUpdate();
        }
        this.loading = false;
      } catch (e) {
        console.log(e);
      }
    },
    async getEnvelopeInfo() {
      try {
        let params = {
          related_id: this.USER_INFO.company_id,
          account_type: 3,
        };
        let result = await this.$store.dispatch(
          "baseConsole/envelopeAaccountInfo",
          params
        );
        if (result.success) {
          this.envelopInfo = result.data;
        }
      } catch (e) {
        console.log(e);
      }
    },
    editChange(row, type) {
      if (!type && !this.inspectButton("mem:yrm:mdi")) return;
      this.drawerTitle = this.$t("editBusinessRedEnvelope");
      let obj = Object.assign({}, row);

      if (type && row.isTimer != 1) {
        this.drawerTitle = this.$t("Detail");
        this.isDisabled = true;
      } else {
        this.isDisabled = false;
      }
      this.drawerShow = true;
      this.$nextTick((e) => {
        obj.today = "";
        obj.nextDay = "";
        this.editDatas = obj;
      });
    },
    addChange() {
      this.drawerTitle = this.$t("addBusinessRedEnvelope");
      if (this.$refs.addOrEdit) {
        this.$refs.addOrEdit.clearData();
      }
      this.isDisabled = false;
      this.drawerShow = true;
    },
    handlePageChange(page) {
      this.start = page;
      this.getList();
    },
    searchChange(params) {
      this.start = 1;
      let _params = Object.assign(params, { topic: params.name });
      delete _params.name;
      this.getList(_params);
    },
    isSaveChange(val) {
      this.drawerShow = false;
      if (val) {
        this.getList();
        this.getEnvelopeInfo();
      }
    },
    delData(val) {
      if (!this.inspectButton("mem:yrm:del")) return;
      this.delLoading = true;
      this.$confirm(this.$t("delete_envelope?"), this.$t("tipsTitle"), {
        confirmButtonText: this.$t("Confirm"),
        cancelButtonText: this.$t("cancel"),
        type: "warning",
      })
        .then(async () => {
          let params = {
            related_id: this.USER_INFO.company_id,
            red_envelope_business_id: val.id,
            deleted: 1,
          };
          this.optionData(params);
        })
        .catch((e) => {
          console.log(e);
        });
    },
    endData(val) {
      if (!this.inspectButton("mem:yrm:sto")) return;
      this.$confirm(this.$t("distribution_envelopes"), this.$t("tipsTitle"), {
        confirmButtonText: this.$t("Confirm"),
        cancelButtonText: this.$t("cancel"),
        type: "warning",
      })
        .then(async () => {
          let params = {
            related_id: this.USER_INFO.company_id,
            red_envelope_business_id: val.id,
            is_abort: 1,
            already_received_money: val.receive_amount,
          };
          this.optionData(params);
        })
        .catch((e) => {
          console.log(e);
        });
    },
    async optionData(params) {
      // 删除或者终止
      let result = await this.$store.dispatch(
        "baseConsole/addorEditRedEnvelope",
        params
      );
      if (result.success) {
        if (params.deleted) {
          this.$delTip();
        } else {
          this.$osTip();
        }
        this.getList();
        this.getEnvelopeInfo();
      }
      this.delLoading = false;
    },
    toReceive(row, type) {
      if (!this.inspectButton("mem:yrm:lqd")) return;
      let params = {
        envelope_id: row.id,
      };
      if (type == 2) {
        params.status = 2;
      }
      this.$router.push({
        path: "/console/conference/receiveBusiness",
        query: {
          parameter: this._encode(params),
        },
      });
    },
    showWith() {
      this.$refs.writeOff.showDialog();
    },
    //核销后刷新
    refresh(){
      this.getList();
      this.getEnvelopeInfo();
    }
  },
};
</script>
<style lang="less" scoped>
.redEnvelopeAdmin {
  .envelopTotal {
    margin-bottom: 12px;
  }
  .titleSlot {
    padding-right: 12px;
  }
  .search {
    margin: 20px 0 12px;
  }
  .envelopList {
    background: #fff;
    min-height: 500px;
    padding-bottom: 15px;
  }
}
.color div {
  color: #808080;
}
.redcolor {
  color: #f5222d;
  margin-right: 2px;
}
/deep/.el-drawer__body {
  overflow-y: auto;
}
</style>